<template>
  <footer class="footer">
    <h5 class="footer__description">
      <p>Інформаційні послуги з обміну валют.</p>
      <p>Фінансові консультації, надійність та конфіденційність.</p>
      <p>© 2014-2023 Carte Blanche Sp. z o.o.</p>
      <p>Усі права застережені.</p>
    </h5>
    <nav class="footer__badges">
      <a class="footer__badge" target="_blank" href="https://play.google.com/store/apps/details?id=com.maturedevs.goverla">
        <img src="../assets/google-play-badge-us.svg" alt="Download on Google Play" />
      </a>
      <a class="footer__badge" target="_blank" href="https://apps.apple.com/us/app/goverla-ua/id1528716737">
        <img src="../assets/app-store-badge-us.svg" alt="Download on App Store" />
      </a>
    </nav>
    <nav class="footer__links">
      <router-link class="footer__link" to="/terms">Умови викорстання</router-link>
      <router-link class="footer__link"  to="/policy">Політика конфіденційності</router-link>
      <a class="footer__link" href="https://retro.goverla.ua/?utm_source=modern&utm_medium=footer">Ретро Говерла</a>
    </nav>
    <div class="footer__buttons">
      <ContactButton style="color: #56606B" href="https://twitter.com/goverlaua" icon="ic-twitter"/>
      <ContactButton style="color: #56606B" href="https://t.me/goverla_ua" icon="ic-telegram"/>
      <ContactButton style="color: #ffffff" href="tel:+380503397777" icon="ic-phone" text="(050) 339-77-77"/>
    </div>
    <MobilePopup/>
  </footer>
</template>

<script>
import ContactButton from "@/components/ContactButton";
import MobilePopup from "@/components/MobilePopup";

export default {
  name: "Footer",
  components: {
    ContactButton,
    MobilePopup,
  },
}
</script>

<style scoped>
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 80px;
  margin: 24px 0;
}
.footer__description {
  height: min-content;
  padding-left: 20px;
  color: #6D7A88;
  max-width: 420px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  border-left: 3px solid #FD413C;
}
.footer__buttons {
  display: none;
  margin: 16px 0;
}
.footer__badges {
  margin: 10px 0;
}
.footer__badge {
  margin: 10px;
}
.footer__links {
  display: flex;
  justify-content: flex-end;
}
.footer__link {
  color: #6D7A88;
  font-size: 14px;
  margin: 10px;
  font-weight: 400;
  line-height: 21px;
}
.footer__badge {
  width: 120px;
  height: 40px;
}
@media (max-width: 1023px) {
  .footer {
    align-items: center;
    flex-direction: column;
    height: auto;
    padding: 0 10px;
    margin: 10px 0 40px 0;
  }
  .footer__buttons {
    display: flex;
  }
  .footer__link {
    text-align: center;
    color: #6D7A88;
    font-size: 12px;
  }
  .footer__description {
    text-align: center;
    border-left: none;
  }
}
</style>