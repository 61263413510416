<template>
  <Container class="rates">
    <div class="rates__caption">
      <header>Курс Валют</header>
      <time :datetime="point.updatedAt">{{ updated_at }}</time>
    </div>
    <div class="rates__header">
      <div class="rates__header__currency">Валюта</div>
      <div class="rates__header__value">Купівля</div>
      <div class="rates__header__value">Продаж</div>
    </div>
    <div class="rates__rows">
      <RatesRow v-for="rate in point.rates" :key="rate.id" :rate="rate" @update:rate="update($event)" />
    </div>
  </Container>
</template>

<script>
import { format, parseISO } from "date-fns";
import RatesRow from "@/components/RatesRow";
import Container from "@/components/Container";

const HOME_ALIAS = 'goverla-ua';

export default {
  name: "RatesTable",
  components: {
    Container,
    RatesRow
  },
  data() {
    return {
      point: {
        rates: []
      }
    }
  },
  methods: {
    update: function (rate) {
      this.$apollo.mutate({
        mutation: require('../graphql/UpdatePointRates.graphql'),
        variables: {
          input: {
            id: this.point.id,
            rates: [rate]
          }
        }
      }).catch((error) => console.error(error));
    }
  },
  computed: {
    updated_at: function () {
      return (this.point.updatedAt) ? format(parseISO(this.point.updatedAt), "dd/MM/yy") : '';
    }
  },
  apollo: {
    point: {
      query: require('../graphql/Point.graphql'),
      fetchPolicy: "network-only",
      pollInterval: 60000,
      variables: {alias: HOME_ALIAS},
      update: data => data.point,
      error: (err) => console.error(err),
      subscribeToMore: {
        document: require('../graphql/RatesSubscription.graphql'),
        updateQuery: ((previousQueryResult, {subscriptionData}) => {
          if (!subscriptionData.data) return previousQueryResult;
          const updates = subscriptionData.data.subscribeRates;
          const updatesById = Object.assign({}, ...updates.map(s => ({[s.id]: s})));
          if (previousQueryResult) {
            previousQueryResult.point.rates.map(prev => {
              const updateById = updatesById[prev.id]
              if (!updateById) return prev;
              if (updateById.bid) prev.bid = updateById.bid;
              if (updateById.ask) prev.ask = updateById.ask;
              return prev
            });
          }
          return previousQueryResult
        })
      }
    }
  }
}
</script>

<style>
.rates {
  min-width: 480px;
  min-height: 890px;
  display: flex;
  flex-direction: column;
}
.rates__caption {
  display: flex;
  height: 56px;
  padding: 0 24px;
  align-items: center;
  justify-content: space-between;
}
.rates__caption header {
  font-size: 18px;
  font-weight: 900;
}
.rates__caption time {
  font-size: 16px;
  font-weight: 400;
}
.rates__header {
  display: flex;
  align-items: center;
  height: 40px;
  color: #6D7A88;
  padding-left: 16px;
  font-size: 14px;
  font-weight: 700;

}
.rates__header__currency {
  width: 44%;
  padding-left: 8px;
  text-align: left;
}
.rates__header__value {
  width: 28%;
  text-align: center;
}
@media only screen and (max-width: 1023px) {
  .rates {
    width: 100%;
    min-width: auto;
    min-height: 480px;
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .rates__header__currency {
    width: 40%;
  }
  .rates__header__value {
    width: 30%;
  }
}
</style>
