<template>
  <div class="home">
    <router-view></router-view>
    <RatesTable class="home__item" style="order: -1"/>
    <TickersTable class="home__item" style="order: 10"/>
    <Telegram class="home__item twitter" style="order: 20"/>
  </div>
</template>

<script>
import RatesTable from "@/components/RatesTable";
import TickersTable from "@/components/TickersTable";
import Telegram from "@/components/Telegram";
export default {
  name: "Home",
  components: {
    Telegram,
    TickersTable,
    RatesTable,
  },
}
</script>

<style scoped>
.home {
  width: 100%;
  padding: 0 60px;
  display: flex;
}
.home__item {
  width: 33%;
  height: 890px;
  margin: 0 20px;
}
@media only screen and (max-width: 767px) {
  .home {
    flex-direction: column;
    padding: 0;
  }
  .home__item {
    height: initial;
  }
}
@media only screen and (max-width: 1023px) {
  .home {
    flex-direction: column;
  }
  .home__item {
    width: auto;
    margin: 20px 0;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1673px) {
  .home {
    width: 1000px;
    margin: 0 auto;
    max-height: 1200px;
    padding: 0 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }
  .home__item {
    height: min-content;
    margin: 0 10px 0 10px;
  }

  .home__item.twitter {
    order: 1 !important;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 1684px) {
  .home__item.twitter {
    margin-left: 40px;
  }
}
</style>